import "./App.css";
import "./index.css";
import Tab from "./components/Tab";
import Profile from "./components/Profile";
import React, { useRef, useEffect, useState } from "react";
import Resume from "./components/Resume";
import "./components/scripts/anims";
import Card from "./components/Card";
import Contact from "./components/Contact";
import JoshResume from "./components/Josh-Kistler-Resume.pdf";

function App() {
  return (
    <div className="App">
      <div className="header">
        <div className="backer"></div>
        <Tab name="Home" src="#resume-link"></Tab>
        <Tab name="About" src="#about"></Tab>
        <Tab name="Projects" src="#card-url"></Tab>
        <Tab name="Contact" src="#contact-url"></Tab>
      </div>
      <div className="mainPage">
        <div id="resume-link">
          <Resume></Resume>
        </div>

        <div id="about">
          <Profile name="About Me" url=""></Profile>
        </div>
        <h1>Projects</h1>
        <div id="card-url" className="card-wrapper">
          <Card
            title="C# Project"
            imageUrl="https://global-uploads.webflow.com/6047a9e35e5dc54ac86ddd90/63065002cd563e1f8fcead3b_fdcad5ca.png"
            body="This is a link to the source code for a C# project I developed. It is a simple memory game, but proved valuable in teaching me the basics of C# and .NET development."
            link="https://github.com/Joshypooh98/CS_Memory_Game"
            linktext="View Source Code"
          ></Card>
          <Card
            title="PHP Database Project"
            imageUrl="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/256_Php_logo-512.png"
            body="This is a link to my capstone project from my senior year of college. This project gave me lots of experience in back-end development using PHP and managing MySQL databases. It also taught me basic server maintenance as I had to launch my own LAMP stack to hose the database."
            link="https://joshfinal.com/index.php"
            linktext="Visit Database"
          ></Card>
          <Card
            title="React Framework Project"
            imageUrl="https://ionicframework.com/docs/icons/logo-react-icon.png"
            body="This is a link to a simple memory game built in the React Framework. While simple in nature, this project taught me valuable assets within the framework itself such as useState, useEffect, and developing reusable components for a web application."
            link="./memory/index.html"
            linktext="Visit Site"
          ></Card>
        </div>
        <div id="contact-url">
          <div className="contact-wrapper">
            <div className="inner-contact-wrapper">
              <Contact
                name="Joshua Kistler"
                email="kistlerjj9@hotmail.com"
                linkedin="https://www.linkedin.com/in/joshua-kistler-660316230"
                phone="219-779-3298"
              ></Contact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

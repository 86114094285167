import "./Profile.css";
import josh from "./pics/josh.jpg";

export default function Profile({ url, name, description }) {
  return (
    <div className="fullProfile">
      <div className="monk-wrapper">
        <img src={josh} alt=""></img>
        <div className="infoWrapper">
          <h1 className="profileH1">{name}</h1>
          <div className="bioP">
            <p>
              Welcome to my page! My name is Joshua Kistler, I am a recent grad
              of Purdue University looking to kick start my career in web
              development. <br />
              <br /> I have experience in HTML, CSS, Javascript(Jquery), PHP,
              MySQL, and the React Framework. I enjoy back-end development the
              most, and it was the focus of my senior year of college as I
              undertook a database management project for a real world client.{" "}
              <br /> <br /> I am looking to hone my skills and gather
              professional experience, so please feel free to reach out with my
              contact info below!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./Resume.css";
import JoshResume from "./Josh-Kistler-Resume.pdf";

export default function Resume() {
  return (
    <>
      <div className="Resume">
        <div className="resumeContainer">
          <h1>Portfolio</h1>
          <p>
            Hello! I am a recent graduate from Purdue University with a Bachelor
            of Science in Web Programming and Design.
          </p>
          <a href="https://drive.google.com/file/d/1kzPtEljQwJh7VD2ltkLsV83LtCAdMFX7/view?usp=sharing">
            <div className="resumeBacker"></div>
            <div className="resumeBlock">
              <br></br>
              <br></br>
              <h3>Resume</h3>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}

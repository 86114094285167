import "./Tab.css";

export default function Tab({ name, src }) {
  return (
    <div className="Tab">
      <a href={src}>
        <h1>{name}</h1>
      </a>
    </div>
  );
}

import React from "react";
import "./Contact.css";

export default function Contact({ email, phone, linkedin, name }) {
  return (
    <div>
      <div className="contact-backer">
        <div className="contact-backer2">
          <h2>Name:</h2>
          <h3>{name}</h3>
          <br></br>
          <h2>Email:</h2>
          <h3>{email}</h3>
          <br></br>
          <h2>Phone:</h2>
          <h3>{phone}</h3>
          <br></br>
          <h2>LinkedIn:</h2>
          <h3>
            <a href={linkedin}>Joshua Kistler's LinkedIn</a>
          </h3>
          <br></br>
        </div>
      </div>
    </div>
  );
}
